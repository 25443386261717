import _ from "lodash";

const microsoftWord = [
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
];

const microsoftExcel = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
];

const microsoftPowerpoint = [
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
];

const apple = ["application/x-iwork-pages-sffpages"];

const adobe = ["application/pdf"];

const textDocMimeTypes = _.concat(microsoftWord, ["text/plain"]);

const spreadsheetMimeTypes = _.concat(microsoftExcel, ["text/csv"]);

const presentationMimeTypes = microsoftPowerpoint;

const all = _.concat(
  textDocMimeTypes,
  spreadsheetMimeTypes,
  presentationMimeTypes,
  apple,
  adobe
);

const pdf = _.concat(adobe);
const word = _.concat(microsoftWord);

export { all, pdf, word };
